<template>
	<div>
		<v-card>
			<s-toolbar label="Impresion QR recepcion" dark color="#8e8f91"></s-toolbar>
			<v-container>
				<v-row style="margin:auto" justify="center">
					<v-col cols="12" md="3" lg="3">
						<s-date @input="inputDate($event)"> </s-date>
					</v-col>
					<v-col cols="12" md="3" lg="3">
						<s-select
							:items="itemsNumberGuide"
							item-value="RcfID"
							full
							autocomplete
							item-text="RcfNumberHarvestGuide"
							label="Seleccione Nro. Guia de cosecha"
							v-model="objNumberGuide"
							return-object
							@input="inputNumberGuide($event)"
						></s-select>
					</v-col>
				</v-row>
				<v-row justify="center" style="margin:auto">
					<v-col lg="5">
						<b>Productor:</b> {{ viewItemsAll.PrdCardName }}
					</v-col>
					<v-col lg="4"> <b>Lote: </b>{{ viewItemsAll.FltName }} </v-col>
				</v-row>
				<v-row justify="center" style="margin-top: -20px; font-size: smaller;">
					<v-col lg="3">
						<b>Tipo Cultivo:</b> {{ viewItemsAll.TypeCropName }}
					</v-col>
					<v-col lg="3">
						<b>Cultivo:</b> {{ viewItemsAll.TypeCultiveName }}
					</v-col>
					<v-col lg="3"> <b>Variedad: </b>{{ viewItemsAll.VrtName }} </v-col>
				</v-row>
			</v-container>
			<v-row style="margin:auto" justify="center">
				<div class="col-md-12 col-sm-12">
					<s-toolbar
						label="Listado de Pesos"
						dark
						:color="'warning'"
						class="mr-4 ml-2"
						style="display: flex;"
						print
						@print="printQR()"
					></s-toolbar>
					<v-row class="ml-4">
						<v-col cols="12" lg="12">
							<v-data-table
								disable-sort
								item-key="WpbID"
								:headers="headersWeighed"
								:items="itemsWeighed"
								v-model="selectedWeighed"
								dense
								hide-default-footer
								:items-per-page="-1"
								@click:row="rowClickWeighed"
							>

								<template v-slot:item.xCount={item}>
									<v-chip
										x-small
										
										:color="item.xCount >= 1 ? 'info' : 'error'"
									>
										{{item.xCount >= 1 ? 'Abastecido' : 'Sin Abast.'}}
									</v-chip>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</div>
			</v-row>
			<v-container id="printMe">
				<v-row justify="center" style="display: grid;">
					<v-col cols="12">
						<qr-code :text="textQR" error-level="H" :size="360"> </qr-code>
					</v-col>
				</v-row>
			</v-container>

			<v-dialog v-model="printqrmodal" fullscreen>
				<print-qr :textQR="textQR"></print-qr>
			</v-dialog>
		</v-card>
	</div>
</template>

<script>
	import _sWeighedByBatch from "../../../services/FreshProduction/WeighedByBatchService";
	import _sReceptionFresh from "../../../services/FreshProduction/ReceptionFreshService";
	import qrCode from "vue-qr-generator";
	import { jsPDF } from "jspdf";
	import PrintQr from './PrintQr.vue';

	export default {
		props: {

			ship: {
				type: Number,
				default: 1,
			},
		},
		components: { qrCode, PrintQr },
		data: () => ({
			base64: null,
			printqrmodal: false,
			objNumberGuide: [],
			viewItemsAll: {},
			itemsNumberGuide: [],
			itemsGang: [],
			itemsWeighed: [],
			headersWeighed: [
				{ text: "Fecha/Hora", value: "WpbDate", width: 150 },
				{ text: "Nro. Palet", value: "WpbNumberPallet", width: 80 },
				{ text: "Cant. Jabas", value: "WpbJabasQuantity", width: 100 },
				{ text: "Peso Jaba", value: "WpbWeightJaba", width: 100 },
				{ text: "Peso Parihuela", value: "WpbWeightPallet", width: 100 },
				{ text: "Peso Bruto", value: "WpbWeightGross", width: 150 },
				{ text: "Peso Neto", value: "WpbWeightNet", width: 100 },
				{
					text: "Peso Prom. Jaba",
					value: "WpbWeightAverageJaba",
					width: 100,
				},
				{ text: "Estado", value: "xCount", width: 100}
			],
			RcfGngID: {},
			viewItemsGang: {},
			RcfID: 0,
			selectedWeighed: [],
			textQR: "Sin datos",
		}),

		methods: {
			inputNumberGuide(obj) {
				this.itemsGang = [];

				if (obj !== null || obj !== undefined) {

                    this.viewItemsAll = this.objNumberGuide
					this.getWeighedByBatch(obj.RcfID);
				}
			},

			//Obtener Pesadas
			getWeighedByBatch() {
				_sWeighedByBatch
					.getWeighedBatch(
						{
							RcfID: this.viewItemsAll.RcfID, //this.viewItemsGang.RcfID,
							RcfGngID: 0, //this.viewItemsGang.RcfGngID,
							TypeDoor: 0,
							xPrint: 1,
						},
						this.$fun.getUserID(),
					)
					.then(r => {
						this.itemsWeighed = r.data; //Registros de Pesada
						this.itemsWeighed.forEach(element => {
							element.WpbDate = this.$moment(element.WpbDate).format(
								this.$const.FormatDateTimeDB,
							);
						});
						console.log("this.itemsWeighed", this.itemsWeighed);
					});
			},

			rowClickWeighed: function(item, row) {
				this.selectedWeighed = [item];
				console.log(this.selectedWeighed);
				var cadTextQR =
					this.selectedWeighed[0].RcfID.toString() +
					", " +
					this.selectedWeighed[0].WpbID.toString() +
					", " +
					this.selectedWeighed[0].WpbJabasQuantity +
					", " +
					this.selectedWeighed[0].WpbYuliano +
					", LP-" +
					this.selectedWeighed[0].RcfID.toString() +
					", Cant. Jabas: " +
					this.selectedWeighed[0].WpbJabasQuantity +
					", PP. Jaba: " +
					this.selectedWeighed[0].WpbWeightAverageJaba +
					", PN: " +
					this.selectedWeighed[0].WpbWeightNet +
					", PB: " +
					this.selectedWeighed[0].WpbWeightGross +
					", " +
					this.selectedWeighed[0].WpbDate +
					", " +
					this.selectedWeighed[0].TypeDoorName;

				this.textQR = cadTextQR;

				
			},

			inputDate(val) {
				(this.itemsNumberGuide = []),
					_sWeighedByBatch
						.getHarvestGuide(
							{ xPrint: 1, xDate: val, TypeShip: 2 },
							this.$fun.getUserID(),
						)
						.then(r => {
							if (r.data.length > 0) {
								this.itemsNumberGuide = r.data;
							}
						});
			},

			encodeBase64(img)
			{
				var canvas = document.createElement("canvas");
				canvas.width = img.width;
				canvas.height = img.height;
				var ctx = canvas.getContext("2d");
				ctx.drawImage(img, 0, 0);
				var dataURL = canvas.toDataURL();
				return dataURL;
			},

			async printQR () {
				// Pass the element id here
				//await this.$htmlToPaper('printMe');
				//this.printqrmodal = true;
				/* console.log('eeee ', this.selectedWeighed);
				var img = document.querySelector("img");
				const doc = new jsPDF();
				doc.addImage(img, "JPEG", 5, 5);
				doc.save(this.selectedWeighed[0].WpbCorrelative+'.pdf'); */

				var img = document.querySelector("img");

				var base6 = this.encodeBase64(img);
				
				
				/* var base64 = await this.encodeBase64(); */
				//console.log('imgggg ', base64);

				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 500);}\n" +
						"function step2(){window.print()}\n" +
						"</scri" +
						"pt><style media='print'>  @media print  { @page { margin-left: 0.5cm; {} </style></head><body onload='step1()'> \n" +
						"<div style='margin-top:100px ' class='col col-12 justify-center'>",
				);

				pwa.document.write(
					"<div style='margin: 20px 20px 20px 0px;' id='col1' ><img alt='Scan me!' style='display: block;' src='" +
						base6 +
						"' /></div>",
				);
				pwa.document.write("<br><div ><table border='0'>");//WpbCorrelative

				pwa.document.write(
					"<tr><td>N° Pallet Ingreso: </td><td>" +
						/* this.selectedWeighed[0].WpbID + " | " + */ this.selectedWeighed[0].WpbNumberPallet +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Lote de Materia Prima: </td><td>" +
						/* this.selectedWeighed[0].WpbID + " | " + */ this.selectedWeighed[0].WpbYuliano +
						" </td></tr>",
				);
				/* pwa.document.write(
					"<tr><td>Lote Produccion: </td><td>" +
						this.viewItemsAll.RcfID +
						" </td></tr>",
				); */
				pwa.document.write(
					"<tr><td>Nombre Proveedor: </td><td>" +
						this.viewItemsAll.PrdCardName +
						" </td></tr>",
				);
				/* pwa.document.write(
					"<tr><td>Fundo: </td><td>" +
						this.viewItemsAll.FltName +
						" </td></tr>",
				); */
				pwa.document.write(
					"<tr><td>Cultivo: </td><td>" +
						this.viewItemsAll.TypeCultiveName +
						 
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Variedad: </td><td>" +
						this.viewItemsAll.VrtName +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Tipo Cultivo: </td><td>" +
						this.viewItemsAll.TypeCropName +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Tipo de Envase: </td><td>" +
						this.selectedWeighed[0].TypeChargeName +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Cantidad: </td><td>" +
						this.selectedWeighed[0].WpbJabasQuantity +
						" </td></tr>",
				);
				
				pwa.document.write(
					/* "<tr><td>Peso Bruto: " +
						this.selectedWeighed[0].WpbWeightGross + */
						" </td><td> Peso de Ingreso: </td><td>" +
						this.selectedWeighed[0].WpbWeightNet +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Peso Promedio: </td><td>" +
						this.selectedWeighed[0].WpbWeightAverageJaba +
						" </td></tr>",
				);
				
				pwa.document.write(
					"<tr><td>Procedencia: </td><td>" +
						this.selectedWeighed[0].TypeZonLocationName +
						" </td></tr>",
				);
				/* pwa.document.write(
					"<tr><td>Puerta: </td><td>" +
						this.selectedWeighed[0].TypeDoorName +
						" </td></tr>",
				); */

				/* pwa.document.write(
					"<tr><td>Certificaciones: </td><td>" +
						this.RcfGngID.TcDescription +
						" </td></tr>",
				); */

				pwa.document.write("</table></div>");

				pwa.document.write("</div></body></html>");
				pwa.document.close();
			
			}
		},
	};
</script>

<style></style>
